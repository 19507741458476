.profile-pic .tumbnail .edit input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  cursor: pointer;
  opacity: 0;
}
.profile-pic {
  display: flex;
  align-items: flex-end;
}
.profile-pic .tumbnail {
  /* max-width: 200px; */
  /* padding: 16px; */
  background: #f6f6f6;
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.profile-pic .tumbnail img {
  max-width: 100%;
}
.profile-pic .tumbnail .edit {
  position: absolute;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}
/* .profile-pic .brief {
	padding-bottom: 30px;
	padding-left: 20px;
}
.profile-pic .brief strong {
	font-size: 20px;
	font-weight: 600;
} */

.watermark {
  width: 300px;
  height: 40px;
  display: block;
  position: relative;
}

.watermark::after {
  content: '';
  background: url('./../images/TD_Logo_Default.png');
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

/* mobile splash css*/
.mobile-splash .tumbnail .edit input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  cursor: pointer;
  opacity: 0;
}
.mobile-splash {
  display: flex;
  align-items: flex-end;
}
.mobile-splash .tumbnail {
  /* max-width: 200px; */
  /* padding: 16px; */
  background: #f6f6f6;
  height: 300px;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.mobile-splash .tumbnail img {
  max-width: 100%;
}
.mobile-splash .tumbnail .edit {
  position: absolute;
  right: 0px;
  top: 0px;
  overflow: hidden;
}

.mobile-splash .tumbnail .edit-del {
  position: absolute;
  right: 50px;
  top: 0px;
  overflow: hidden;
}

/* tab splash css*/
.tab-splash .tumbnail .edit input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  cursor: pointer;
  opacity: 0;
}

.tab-splash {
  display: flex;
  align-items: flex-end;
}
.tab-splash .tumbnail {
  /* max-width: 200px; */
  /* padding: 16px; */
  background: #f6f6f6;
  height: 200px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.tab-splash .tumbnail img {
  max-width: 100%;
}
.tab-splash .tumbnail .edit {
  position: absolute;
  right: 0px;
  top: 0px;
  overflow: hidden;
}
.tab-splash .tumbnail .edit-del {
  position: absolute;
  right: 50px;
  top: 0px;
  overflow: hidden;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.scrim {
  background-color: rgba(0, 0, 0, 0.3);
}

.login-input {
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: background-color 0.3s ease;
  border: none;
  height: 50px;
  margin-top: 1.25rem;
  background-color: rgba(247, 247, 249, 0.7);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
