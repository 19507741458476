.style {
  table,
  th,
  td {
    @apply border-collapse border border-gray-100 p-4 text-center;
  }

  td {
    @apply text-black;
  }
}
